<template>
  <div :style="containerStyle" class="container">
    <!-- @/assets/image/questionnaire/listBackground.png -->
    <div class="start">
      <!-- @/assets/image/questionnaire/startImg.png -->
      <img :src="form.titleImage ? form.titleImage : ''" class="head" />
      <div class="body">
        <p class="body-title">
          {{ form.title }}
        </p>
        <p class="body-sub">
          {{ form.questionnaireNote }}
        </p>
        <div class="body-line"></div>
        <van-field v-for="(item, index) in this.form.questionnaireInfoCollects" :key="index" v-model="item.infoValue"
          :label="`${item.infoName}：`" class="my-field" :required="item.isFill == 1 ? true : false"
          :error-message="item.infoName == '红柚联系人手机号' ? errorMessage(item) : ''" />
        <div class="body-line"></div>
      </div>
    </div>

    <div class="fill">
      <div class="body">
        <div v-for="(item, index) in list" :key="index">
          <div class="body_item" v-if="item.isShow">
            <singleChoice v-if="item.subType == 1" :item="item" :index="index" :review="review" :list="list" :type="2"
              :questionnaireShow="form.questionnaireShow" @edit="editList" @end="submit" />
            <questionAnswer v-else-if="item.subType == 2" :type="2" :list="list" @edit="editList" :item="item"
              :index="index" :review="review" :questionnaireShow="form.questionnaireShow" />
            <multipleChoice v-else-if="item.subType == 3" :type="2" :list="list" @edit="editList" :item="item"
              :index="index" :review="review" :questionnaireShow="form.questionnaireShow" />
            <blanks v-else-if="item.subType == 4" :type="2" :list="list" @edit="editList" :item="item" :index="index"
              :review="review" :questionnaireShow="form.questionnaireShow" />
            <multipleBlanks v-else-if="item.subType == 5" :type="2" :list="list" @edit="editList" :item="item"
              :index="index" :review="review" :questionnaireShow="form.questionnaireShow" />
            <choiceBlanks v-else-if="item.subType == 6" :type="2" :list="list" @edit="editList" :item="item"
              :index="index" :review="review" :questionnaireShow="form.questionnaireShow" />
            <smallBlank v-else-if="item.subType == 7" :type="2" :list="list" @edit="editList" :item="item"
              :index="index" :review="review" :questionnaireShow="form.questionnaireShow" />
          </div>
        </div>
        <div class="body_button">
          <van-button v-if="!review" @click="submit()" type="primary" color="linear-gradient(90deg, #FB7A08, #FE6113)"
            class="body_button_submit">
            <span v-if="form.submitButton == '02'">SUBMIT</span>
            <span v-else>提交</span>
          </van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  getQuestionnaireInfo,
  submitQue,
} from '@/api/questionnaire.js'
import { addAnonymityUser } from '@/api/login'
import singleChoice from '../components/singleChoice'
import questionAnswer from '../components/questionAnswer'
import multipleChoice from '../components/multipleChoice'
import blanks from '../components/blanks'
import multipleBlanks from '../components/multipleBlanks'
import choiceBlanks from '../components/choiceBlanks'
import smallBlank from '../components/smallBlank';
import { phoneList } from './list'
export default {
  data() {
    return {
      review: false,
      paperId: '', //试卷id
      list: [],
      form: {},
      image: {},
      page: 0, //当前页码
      phoneReg: new RegExp(
        /^1(3\d|4[5-9]|5[0-35-9]|6[567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
      ),
      phoneList: [],
      loading: false
    }
  },
  created() {
    this.userId = this.$store.getters.user.id
    if (this.$route.query.review) {
      this.review = JSON.parse(this.$route.query.review)
    }
    this.paperId = this.$route.query.id
    this.form = this.$store.getters.getQuestionnaireInfo
    document.title = this.form.title

    this.getInfo()

    // 临时添加
    let str = phoneList.replace(/\ +/g, "");
    str = str.replace(/[\r\n]/g, "");
    str = str.replace(/[\t]/g, " ");
    this.phoneList = str.split(',')
  },
  components: {
    singleChoice,
    questionAnswer,
    multipleChoice,
    blanks,
    multipleBlanks,
    choiceBlanks,
    smallBlank
  },
  computed: {
    containerStyle() {
      if (this.form.backgroundImage) {
        return { backgroundImage: `url('${this.form.backgroundImage}')`, 'background-size': '100% auto' }
      } else {
        return { backgroundColor: this.form.backgroundColor }
      }
    },
    errorMessage() {
      return function (query) {
        for (const item of this.phoneList) {
          if ((item.indexOf(query.infoValue) != -1 && query.infoValue.length == 11) || !query.infoValue) {
            return ''
          }
        }
        return '手机号错误'
      }
    }
  },
  methods: {
    getInfo() {
      getQuestionnaireInfo(1, this.paperId).then((res) => {
        this.list = res.result
        for (const item of this.list) {
          if (item.subType == 4 || item.subType == 7) {
            item.subAnswer = []
          }
          // 根据问卷形式判断是否全部展开
          if (this.form.questionnaireShow == '02') {
            item.isShow = true
          } else {
            item.isShow = false
          }
        }
        this.list[0].isShow = true
      })
    },
    // 提交
    async submit() {
      if (this.loading) {
        return
      }
      this.loading = true
      let params = this.form.questionnaireInfoCollects
      for (const item of params) {

        if (item.isFill == 1 && !item.infoValue) {
          this.$toast.fail(`请输入${item.infoName}！`)
          this.loading = false
          return
        }

        // if (item.infoName == '红柚联系人手机号') {

        //   if (this.errorMessage(item) == '手机号错误') {
        //     this.$toast.fail(`请输入正确的手机号！`)
        //     return
        //   }
        // }

        item.collectId = item.id
      }
      for (const item of params) {
        delete item.id
      }
      let res = await addAnonymityUser(params)
      let userId = res.result.id

      // 提交前处理数据
      const arr = []
      for (const item of this.list) {
        if (this.form.isAnswer == '01') {
          // 全部填写完毕才可以提交
          if (item.subAnswer == null || item.subAnswer.length == 0) {
            this.loading = false
            this.$toast.fail('请将试题填写完整');
            return;
          }
        }
        let obj = {
          userId: userId,
          questionnaireId: this.paperId,
          subId: item.id,
        }
        if (item.subAnswer != undefined) {
          if (item.subType == 1) {
            obj.score = item.options[item.subAnswer].score
            obj.subAnswer = String.fromCharCode(65 + item.subAnswer)
          } else if (item.subType == 2) {
            obj.score = 0
            obj.subAnswer = item.subAnswer
          } else if (item.subType == 3) {
            obj.score = 0
            for (const items of item.subAnswer) {
              obj.score += item.options[items].score
              if (obj.subAnswer == undefined) {
                obj.subAnswer = String.fromCharCode(65 + items)
              } else {
                obj.subAnswer += `##${String.fromCharCode(65 + items)}`
              }
            }
          } else if (item.subType == 4 || item.subType == 7) {
            for (const items of item.subAnswer) {
              if (obj.subAnswer == undefined) {
                obj.subAnswer = items
              } else {
                obj.subAnswer += `##${items}`
              }
            }
          } else if (item.subType == 5) {
            let str = ''
            for (const items of item.subAnswer) {
              let fill = item.options[items].supplement
                ? '#' + item.options[items].supplement
                : ''
              str += `##${String.fromCharCode(items + 65)}${fill}`
            }
            str = str.substring(2, str.length)
            obj.subAnswer = str
          }
        }
        arr.push(obj)
      }
      submitQue(arr).then((res) => {
        if (res.code == 200) {
          this.$toast.success('提交成功!')
          this.$router.push({
            path: '/questionnaire/anonymity/end',
            query: this.$route.query,
          })
        }
      })
    },
    //关闭
    close() {
      this.$router.push({
        path: '/questionnaire/start',
        query: this.$route.query,
      })
    },
    editList(event) {
      this.$forceUpdate()
    },
  },
}
</script>
<style lang="less" scoped>
.container {
  height: auto;
  min-height: 100vh;
  overflow: hidden;

  .start {
    text-align: center;

    .head {
      margin-top: 80px;
      width: 100%;
    }

    .body {
      width: 1172px;
      margin-left: 56px;
      border-radius: 56px;
      background-color: white;
      padding: 0 54px 160px;

      &-title {
        padding-top: 50px;
        width: 90%;
        margin-top: 95px;
        margin-left: 5%;
        font-family: Source Han Sans CN;
        color: #1a1a1a;
        line-height: 88px;
        text-align: center;
        font-size: 56px;
        font-weight: 600;
        margin-bottom: 50px;
      }

      &-sub {
        font-size: 48px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        margin-top: 39px;
        margin-bottom: 120px;
        text-align: center;
        word-break: break-all;
        white-space: normal;
        word-wrap: normal;
      }

      &-line {
        width: 997px;
        height: 1px;
        background: #ebedf0;
        margin-left: 88px;
      }
    }
  }

  .fill {
    height: auto;
    overflow: hidden;

    .body {
      padding: 64px 64px;
      text-align: center;
      position: relative;
      z-index: 2;

      &_item {
        width: 100%;
        // min-height: 872px;
        background: #ffffff;
        border-radius: 56px;
        padding: 79px 42px 80px 92px;
        text-align: left;
        margin-bottom: 48px;
      }

      &_button {
        margin-top: 120px;
        text-align: center;
        margin-bottom: 120px;

        &_close {
          width: 320px;
          height: 128px;
          background: #ffffff;
          border: 4px solid #b5b5b6 !important;
          border-radius: 16px;
          color: #b5b5b6 !important;
        }

        &_submit {
          width: 320px;
          height: 128px;
          border-radius: 16px;
          // margin-left: 115px;
        }
      }
    }
  }
}

::v-deep .van-field__label {
  width: auto;
  font-size: 48px;
  font-family: Source Han Sans CN;
  font-weight: 800;
  color: #646566;
}

::v-deep .van-button--normal {
  padding: 0 !important;
}

::v-deep .van-button--normal {
  font-size: 48px !important;
  padding: 0;
}
</style>
